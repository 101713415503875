<template>
  <div class="page-login">
    <c-auth-modal title="Регистрация" :loading="$var('load')" back>
      <template #sub>
        Придумайте пароль для авторизации в приложении
      </template>
      <n-form @submit="submit">
        <n-items>
          <n-input title="Введите пароль" v-bind="$form.input('password')" type="password" />
          <n-button wide color="primary" type="submit">Зарегистрироваться</n-button>
        </n-items>
      </n-form>
    </c-auth-modal>
  </div>
</template>

<script>
export default {
  name: 'PageLogin',
  created() {
    this.$form.init({
      password: '',
    })
    this.$form.rules({
      password: [ 'required', ],
    })
  },
  methods: {
    submit() {
      this.$var('load', true)
      if (this.$form.check()) {
        $api.profile.password(this.$form.get()).then((response) => {
          this.$router.push({ name: 'index', })
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.page-login {

}
</style>
